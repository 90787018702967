<template>
  <div class="order-management">
    <div class="mainbody">
      <div class="info">
        <div class="info-header">
          <b>订单管理</b>
        </div>
        <!-- <a-row type="flex" align="middle">
          <a-col :span="2"> 订单状态：</a-col>
          <a-col :span="2">全部</a-col>
          <a-col :span="2"> 待付款 </a-col>
          <a-col :span="2"> 部分付款 </a-col>
          <a-col :span="2"> 已完成</a-col>
          <a-col :span="2"> 已取消 </a-col>
        </a-row> -->
        <a-row :gutter="32">
          <a-col :span="24" :xl="10">
            <span>合同状态：</span>
            <a-checkable-tag :checked="form.contractStatus === null" :key="null"
              @change="checked(null, 'contractStatus')">全部</a-checkable-tag>
            <a-checkable-tag v-for="(i,index) in contractStatusList" :checked="form.contractStatus === index"
              :key="index" @change="checked(index, 'contractStatus')">
              {{ i }}
            </a-checkable-tag>
          </a-col>
          <a-col :span="24" :xl="14">
            <span>支付状态：</span>
            <a-checkable-tag :checked="form.paymentStatus === null" :key="null"
              @change="checked(null, 'paymentStatus')">全部</a-checkable-tag>
            <a-checkable-tag v-for="(i,index) in payStatusList" :checked="form.paymentStatus === index" :key="index"
              @change="checked(index, 'paymentStatus')">
              {{ i }}
            </a-checkable-tag>
          </a-col>
          <a-col :span="24" :xl="10">
            <span>物流状态：</span>
            <a-checkable-tag :checked="form.logisticsStatus === null" :key="null"
              @change="checked(null, 'logisticsStatus')">全部</a-checkable-tag>
            <a-checkable-tag v-for="(i,index) in logisticsStatusList" :checked="form.logisticsStatus === index"
              :key="index" @change="checked(index, 'logisticsStatus')">
              {{ i }}
            </a-checkable-tag>
          </a-col>
          <a-col :span="24" :xl="14">
            <span>开票状态：</span>
            <!-- <a-checkable-tag :checked="form.logisticsStatus === null" :key="null" @change="form.logisticsStatus === null">全部</a-checkable-tag> -->
            <a-checkable-tag v-for="(i,index) in invoiceStatusList"
              :checked="form.invoiceStatusArray === i.status ? true : form.invoiceStatusArray && form.invoiceStatusArray.includes(i.status)"
              :key="index" @change="checked(i.status, 'invoiceStatusArray')">
              {{ i.name }}
            </a-checkable-tag>
          </a-col>
          <a-col :span="24" :xl="24">
            <span>风控预警：</span>
            <a-checkable-tag :checked="form.riskControlStatus === null" :key="null"
              @change="checked(null, 'riskControlStatus')">全部</a-checkable-tag>
            <a-checkable-tag v-for="(i,index) in riskControlStatusList" :checked="form.riskControlStatus === index"
              :key="index" @change="checked(index, 'riskControlStatus')">
              {{ i }}
            </a-checkable-tag>
          </a-col>
          <a-col :span="10">
            <span>订单日期：</span>
            <a-range-picker style="width: 260px" @change="onChange">
              <a-icon slot="suffixIcon" type="calendar" />
            </a-range-picker>
          </a-col>
          <a-col :span="14">
            <span>搜索：</span>
            <a-input-search v-model="form.searchText" style="width: 380px" placeholder="请输入订单号、卖方名称、下游企业、备注"
              @search="form.pageNum = 1;getlist()" />
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-between" class="row-handle">
          <a-col>
            <a-space :size="18">
              <a-dropdown type="primary" :trigger="['click']" :disabled="loadingBatch">
                <a-button-group>
                  <a-button type="primary" :loading="loadingBatch">
                    批量操作
                    <a-icon type="down" style="font-size: 12px" />
                  </a-button>
                </a-button-group>
                <a-menu slot="overlay">
                  <!-- <a-menu-item key="2" @click="batchPay"> 支付税款 </a-menu-item> -->
                  <a-menu-item key="3" @click="batchInvoice"> 申请开票 </a-menu-item>
                </a-menu>
              </a-dropdown>
              <a-button-group>
                <a-select :value="dowValue" @change="dowValue = $event" class="option-select">
                  <a-select-option v-for="(item) in dowList" :key="item.type">
                    {{item.name}}
                  </a-select-option>
                </a-select>
                <a-button type="primary" icon="download" :loading="loadingDlType" @click="ordersTypeFileDow"></a-button>
              </a-button-group>
            </a-space>
          </a-col>
          <a-col>
            <a-space :size="18">
              <a-button type="primary" @click="$router.push(`/buyercreateorder?entityId=${$route.query.entityId}`)">
                <a-icon type="plus-circle" /> 创建订单
              </a-button>
              <a-button type="primary" :loading="loadingDl" @click="ordersDownload">
                <a-icon type="file-excel" /> 导出搜索结果
              </a-button>
            </a-space>
          </a-col>
        </a-row>
        <!-- <a-row class="contcat">
          <a-col :span="4">
            <a-button type="primary" class="cl69 btn">
              <a-icon type="credit-card" />合并付款
            </a-button>
          </a-col>
          <a-col :span="4">
            <a-button type="primary" class="c130 btn"> 一键下载 </a-button>
          </a-col>
        </a-row> -->
        <!-- 订单 -->
        <!-- :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->
        <div>
          <a-table rowKey="id" :columns="columns" :loading="loading" :row-selection="rowSelection" size="middle" :scroll="{ x: 1500,y:500 }"
            :data-source="orderList.list" :pagination="{
              size: 'middle',
              total: total,
              current: form.pageNum,
              pageSize: form.pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              showTotal: (total) => `共有 ${total} 条数据`,
              showQuickJumper: true,
              onShowSizeChange: (current, pageSize) => {
                form.pageSize = pageSize
                form.pageNum = 1
                this.getlist()
              },
              onChange: (pageNum) => {
                form.pageNum = pageNum
                this.getlist()
              }
            }">
            <template slot="id" slot-scope="text">
              <router-link :to="`/order?id=${text}&entityId=${$route.query.entityId}`" class="id-primary">{{
                text
              }}</router-link>
            </template>
            <template slot="taxableAmount" slot-scope="text">
              <span>{{ text.toFixed(2) }}元</span>
            </template>
            <template slot="vatTaxAmount" slot-scope="text">
              <span>{{ text.toFixed(2) }}元</span>
            </template>
            <template slot="quantity" slot-scope="text, record">
              <span>{{ text }}</span><span>{{ record.unitsMeasure | unit }}</span>
            </template>
            <template slot="invoiceStatus" slot-scope="text">
              <a-badge :color="text | color" :text="text | type"></a-badge>
            </template>
            <template slot="schedule" slot-scope="text, record">
              <p>合同：{{ record.contractPro }}</p>
              <p>金额：{{ record.paymentPro }}</p>
              <p>物流：{{record.logisticsPro }}</p>
            </template>
            <template slot="riskControlStatus" slot-scope="text">
              <a-icon type="exclamation-circle" v-if="text.riskControlStatus==='INCOMPLETE'" style="color:red"/>
              <a-icon type="check-circle" v-else style="color:#8400ff"/>
            </template>
            
            <template slot="remark" slot-scope="text">
              <a-tooltip placement="topLeft">
                <template slot="title">
                  {{text}}
                </template>
                <div class="beyond_eip" style="max-width: 109px">{{text}}</div>
              </a-tooltip>
            </template>
            <template slot="operation" slot-scope="text, record">
              <!-- <p>银行回单</p> -->
              <p v-if="record.invoiceStatus === 'WAIT_APPLY'">
                <a-popconfirm cancelText="取消" okText="确定" title="确定申请?" @confirm="() => putInvo(record.id)">
                  <a href="javascript:;">申请开票</a>
                </a-popconfirm>
              </p>
              <p @click="pay(record)" v-if="record.paymentPro!='100%'">
                <a>支付</a>
              </p>
              <a-popconfirm title="是否要关闭订单？" @confirm="onHandlerCloseOrder(record.id)"
                v-if="record.paymentPro =='0%'">
                <a>关闭订单</a>
              </a-popconfirm>
            </template>
          </a-table>
          <!-- 支付 -->
          <a-modal title="支付" :visible="visible" :footer="null" :width="990" @cancel="cancel">
            <PayMent v-if="visible" :id="$route.query.entityId" :authRoleId="authRoleId" :orderId="orders.orderId" @ok="visible = false;getlist()" />
          </a-modal>

          <!-- 批量开票申请 -->
          <a-modal :visible="visibleInvoice" :footer="null" centered :width="620" @cancel="visibleInvoice = false">
            <p class="modal-t">批量开票申请</p>
            <p class="modal-title">提交反馈</p>
            <div class="modal-info">
              <p>
                <a-icon type="check-circle" class="modal-icon" theme="filled" style="color: #52c41a" /> 申请成功订单
              </p>
              <div class="modal-content">
                <span v-for="(item,index) in applyData.success" :key="index">
                  {{item}}{{applyData.success.length === index + 1 ? '' : ','}}
                </span>
              </div>
              <p>
                <a-icon type="close-circle" class="modal-icon" theme="filled" style="color: #f5222d" /> 申请失败订单 <span
                  class="modal-hint">具体请点击查看订单票流了解</span>
              </p>
              <div class="modal-content">
                <span v-for="(item,index) in [...applyData.failed, ...applyData.unableApplyArr]" :key="index">
                  <router-link :to="`/order?id=${item}&entityId=${$route.query.entityId}&p=5`">{{item}}</router-link>
                  {{applyData.failed.length === index + 1 ? '' : ','}}
                </span>
              </div>
            </div>
            <a-row type="flex" justify="center">
              <a-col>
                <a-button style="width: 120px" type="primary" @click="visibleInvoice = false">知道了</a-button>
              </a-col>
            </a-row>
          </a-modal>

          <!-- 批量开票申请 -->
          <a-modal :visible="visibleInvoiceTab" title="批量申请开票" :footer="null" centered :width="680"
            @cancel="visibleInvoiceTab = false">
            <a-table rowKey="id" :columns="selectColumns" :row-selection="rowSelectionInvoice" size="middle"
              :data-source="selectOrderList" :pagination="false">
              <template slot="invoiceStatus" slot-scope="text">
                <a-badge :color="text | color" :text="text | type"></a-badge>
              </template>
              <template slot="applyStatus" slot-scope="text">
                <a v-if="text === 'WAIT_APPLY'">可申请</a>
                <span v-else style="color: red">不可申请</span>
              </template>
            </a-table>
            <a-row type="flex" justify="center" style="margin-top: 32px">
              <a-space :size="32">
                <a-button type="primary" @click="visibleInvoiceTab = false">取消</a-button>
                <a-button type="primary" :loading="loadingApply" @click="enterApply">确认申请</a-button>
              </a-space>
            </a-row>
          </a-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import cookie from '@/utils/js-cookie.js'
import { orderList, apply, ordersClose, applyInvoice } from '@/api/order'
import { type } from '../../utils/constArr'
import PayMent from './components/payment.vue'
import axios from 'axios'

const LIST_FORM_BUYER = {
  pageNum: 1,
  pageSize: 10,
  searchText: undefined,
  beginTime: undefined,
  endTime: undefined,
  invoiceStatusArray: null,
  logisticsStatus: null,
  contractStatus: null,
  paymentStatus: null,
  riskControlStatus: null
}
function resetQuery() {
  LIST_FORM_BUYER.pageNum = 1
  LIST_FORM_BUYER.pageSize = 10
  LIST_FORM_BUYER.searchText = undefined
  LIST_FORM_BUYER.beginTime = undefined
  LIST_FORM_BUYER.endTime = undefined
  LIST_FORM_BUYER.invoiceStatusArray = null
  LIST_FORM_BUYER.logisticsStatus = null
  LIST_FORM_BUYER.contractStatus = null
  LIST_FORM_BUYER.paymentStatus = null
  LIST_FORM_BUYER.riskControlStatus = null
}
const invoiceState = {
  WAIT_APPLY: '未申请',
  WAIT_AUDIT: '待审核',
  REFUSED: '已拒绝',
  REVIEWED: '审核通过',
  PROCESSING: '开票中',
  WAIT_INVOICE: '待开票',
  INVOICED: '已开票'
}
const color = {
  WAIT_APPLY: '#d9d9d9',
  WAIT_AUDIT: 'orange',
  REFUSED: 'red',
  REVIEWED: 'green',
  PROCESSING: 'green',
  WAIT_INVOICE: 'green',
  INVOICED: 'purple'
}
const dowList = [
  // {
  //   type: 'ALL',
  //   name: '全部下载'
  // },
  // {
  //   type: 'CONTRACT',
  //   name: '合同文件'
  // },
  // {
  //   type: 'BANK_RECEIPT',
  //   name: '银行回单'
  // },
  {
    type: 'INVOICE',
    name: '本页发票'
  },
]
const selectColumns = [
  {
    title: '订单号',
    scopedSlots: { customRender: 'id' },
    dataIndex: 'id'
  },
  {
    title: '申请状态',
    dataIndex: 'invoiceStatus',
    scopedSlots: { customRender: 'applyStatus' }
  },
  {
    title: '开票状态',
    dataIndex: 'invoiceStatus',
    scopedSlots: { customRender: 'invoiceStatus' }
  },
  {
    title: '卖家',
    dataIndex: 'supplierName',
  },
]
export default {
  components: {
    PayMent
  },
  data() {
    this.dateFormat = 'YYYY-MM-DD'
    return {
      dowList,
      dowValue: 'INVOICE',
      checkEntity: cookie.get('checkEntity') || {},
      selectColumns,
      selectOrderList: [],
      columns: [
        {
          title: '订单号',
          scopedSlots: { customRender: 'id' },
          fixed: 'left',
          
          dataIndex: 'id'
        },
        {
          title: '卖家',
          
          dataIndex: 'supplierName',
          scopedSlots: { customRender: 'supplierName' }
        },
        {
          title: '订单总额',
         
          dataIndex: 'contractAmount',
          align: 'right',
          scopedSlots: { customRender: 'contractAmount' }
        },
        {
          title: '金额（不含税）',
         
          dataIndex: 'taxableAmount',
          align: 'right',
          scopedSlots: { customRender: 'taxableAmount' }
        },
        {
          title: '增值税',
          
          align: 'right',
          dataIndex: 'vatTaxAmount',
          scopedSlots: { customRender: 'vatTaxAmount' }
        },
        {
          title: '数量',
          
          dataIndex: 'quantity',
          scopedSlots: { customRender: 'quantity' }
        },
        {
          title: '完成度',
          
          scopedSlots: { customRender: 'schedule' }
        },
        {
          title: '风控预警',
          
          scopedSlots: { customRender: 'riskControlStatus' },
          align: 'center',
        },
        {
          title: '开票状态',
          
          dataIndex: 'invoiceStatus',
          scopedSlots: { customRender: 'invoiceStatus' }
        },
        {
          title: '下游企业',
        
          dataIndex: 'downstreamName',
        },
        {
          title: '备注',
          ellipsis: true,
         
          dataIndex: 'remark',
          scopedSlots: { customRender: 'remark' }
        },
        {
          title: '下单日期',
          
          dataIndex: 'createdTime'
        },
        {
          title: '操作',
          fixed: 'right',
          width: 100,
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      selectedRowKeys: [],
      contractStatusList: ['未签署', '已签署', '部分签署'],
      payStatusList: ['未支付', '已完成', '部分完成'],
      logisticsStatusList: ['未上传', '已完结', '部分完结'],
      riskControlStatusList: ['未完善', '已完善'],
      invoiceStatusList: [{
        name: '全部',
        status: null
      },
      {
        name: '等待申请开票',
        status: 'WAIT_APPLY'
      },
      {
        name: '待审核',
        status: 'WAIT_AUDIT'
      },
      {
        name: '已拒绝',
        status: 'REFUSED'
      },
      {
        name: '审核通过',
        status: 'REVIEWED'
      },
      {
        name: '开票中',
        status: 'PROCESSING'
      },
      {
        name: '等待开票',
        status: 'WAIT_INVOICE'
      },
      {
        name: '已开票',
        status: 'INVOICED'
      }],
      currStatus: 0,
      orderList: {},
      loading: false,
      loadingBatch: false,
      loadingDl: false,
      loadingDlType: false,
      form: LIST_FORM_BUYER,
      total: 0,
      visible: false,
      visibleInvoice: false,
      visibleInvoiceTab: false,
      loadingApply: false,
      selectedInvoiceRowKeys: [],
      applyData: {
        failed: [],
        unableApplyArr: [],
        success: []
      },
      authRoleId: undefined, //权限
      orders: {
        orderId: ''
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    // console.log(333,from);
    if (from.name == 'Order' && !from.meta.isReset) {
      // from.meta.isReset = true
      next()
      return
    }
    resetQuery()
    next()
  },
  filters: {
    unit: (val) => type[val],
    type: (val) => invoiceState[val],
    color: (val) => color[val]
  },
  computed: {
    ...mapGetters(['getRoleP']),
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, e) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectOrderList = e
        },
        getCheckboxProps: record => ({
          props: {
            disabled: !(record.invoiceStatus === 'WAIT_APPLY' || record.taxPaymentPro !== '100%'),
          },
          style: {
            display: (record.invoiceStatus === 'WAIT_APPLY' || record.taxPaymentPro !== '100%') ? '' : 'none'
          }
        }),
      };
    },
    rowSelectionInvoice() {
      return {
        selectedRowKeys: this.selectedInvoiceRowKeys,
        onChange: (selectedRowKeys) => {
          this.selectedInvoiceRowKeys = selectedRowKeys
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.invoiceStatus !== 'WAIT_APPLY',
          },
          style: {
            display: record.invoiceStatus !== 'WAIT_APPLY' && 'none'
          }
        }),
      };
    },
  },
  created() {
    this.authRoleId = this.getRoleP.find((item) => item.entityId == this.$route.query.entityId).authRoleId
    this.authRoleId == 2 && this.columns.pop()
  },
  mounted () {
    this.getlist()
  },
  methods: {
    // 关闭订单
    onHandlerCloseOrder(id) {
      ordersClose(id).then(res => {
        if (res.code === 200) {
          this.$notification.success({
            message: '成功',
            description: '订单关闭成功！'
          })
          this.getlist()
        } else {
          this.$notification.error({
            message: '错误',
            description: res.message
          })
        }
      })
    },
    // 获取列表
    getlist() {
      console.log(this.isReset);
      this.loading = true
      orderList({ ...this.form, invoiceStatusArray: this.form.invoiceStatusArray && this.form.invoiceStatusArray.toString(), purchaserId: this.$route.query.entityId })
        .then((res) => {
          this.selectedRowKeys = []
          this.loading = false
          this.form.pageNum = res.data.pageNum
          this.form.pageSize = res.data.pageSize
          this.total = res.data.total
          this.orderList = res.data
        })
        .catch((r) => {
          console.log(r)
        })
    },
    ordersDownload() {
      this.loadingDl = true
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_API_BASE_URL}/orders/list/download`,
        params: { ...this.form, invoiceStatusArray: this.form.invoiceStatusArray && this.form.invoiceStatusArray.toString(), purchaserId: this.$route.query.entityId },
        responseType: 'blob',
        timeout: 5 * 60 * 1000,
        headers: {
          Authorization: cookie.get('token')
        }
      }).then(res => {
        this.loadingDl = false
        // 创建一个隐藏的a连接，
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        if (window.navigator.msSaveBlob) { //判断了有该方法即为IE浏览器
            try {
                window.navigator.msSaveBlob(blob, '订单列表.xlsx')
            } catch (e) {
                console.log(e);
            }
            return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        // 设置连接
        link.href = URL.createObjectURL(blob)
        link.download = '订单列表.xlsx'
        document.body.appendChild(link)
        // 模拟点击事件
        link.click()
      })
    },
    onChange(date, dateString) {
      this.form.beginTime = dateString[0]
      this.form.endTime = dateString[1]
      this.form.pageNum = 1
      this.getlist()
    },
    // 申请开票
    putInvo(id) {
      apply({ orderId: id }).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.getlist()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    cancel() {
      this.visible = false
    },
    // 点击支付
    pay(record) {
      this.orders.orderId = record.id
      this.visible = true
    },
    checked(e, str) {
      if (str === 'invoiceStatusArray') {
        if (this.form[str]) {
          const index = this.form[str].findIndex(item => item === e)
          if (index > -1) {
            this.form[str].splice(index, 1)
            !this.form[str].length && (this.form[str] = null)
          } else {
            this.form[str] = e ? [...this.form[str], e] : null
          }
        } else {
          this.form[str] = [e]
        }
      } else {
        this.form[str] = e
      }
      this.form.pageNum = 1
      this.getlist()
    },
    onSelectChange(keys) {
      this.selectedInvoiceRowKeys = keys
    },
    batchInvoice() {
      if (this.selectedRowKeys.length > 0) {
        this.selectedInvoiceRowKeys = []
        this.selectOrderList.forEach(item => {
          item.invoiceStatus === 'WAIT_APPLY' && (this.selectedInvoiceRowKeys.push(item.id))
        })
        this.visibleInvoiceTab = true
      } else {
        this.$message.error('请先勾选订单')
      }
      // this.visibleInvoice = true
    },
    batchPay() {
      if (this.selectedRowKeys.length > 0) {
        this.$router.push(`/batchpay?entityId=${this.$route.query.entityId}&orderIds=${this.selectedRowKeys}`)
      } else {
        this.$message.error('请先勾选订单')
      }
    },
    enterApply() {
      this.loadingApply = true
      applyInvoice({ orderIds: this.selectedInvoiceRowKeys }).then(res => {
        this.loadingApply = false
        if (res.code === 200) {
          this.visibleInvoiceTab = false
          const { failed, unableApplyArr, success } = res.data
          this.applyData.failed = failed || []
          this.applyData.unableApplyArr = unableApplyArr || []
          this.applyData.success = success || []
          this.visibleInvoice = true
          this.getlist()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    menuSelect (e) {
      console.log(e);
    },
    ordersTypeFileDow() {
        this.loadingDlType = true
        axios({
        method: 'get',
        url: `${process.env.VUE_APP_API_BASE_URL}/orders/list/curPage/file/dow`,
        params: { ...this.form, invoiceStatusArray: this.form.invoiceStatusArray && this.form.invoiceStatusArray.toString(), dowType: this.dowValue, purchaserId: this.$route.query.entityId },
        responseType: 'blob',
        headers: {
          Authorization: cookie.get('token')
        }
      }).then(res => {
        this.loadingDlType = false
        // 创建一个隐藏的a连接，
        const blob = new Blob([res.data], { type: 'application/zip' })
        if (window.navigator.msSaveBlob) { //判断了有该方法即为IE浏览器
            try {
                window.navigator.msSaveBlob(blob, '订单当前页文件.zip')
            } catch (e) {
                console.log(e);
            }
            return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        // 设置连接
        link.href = URL.createObjectURL(blob)
        link.download = '订单当前页文件.zip'
        document.body.appendChild(link)
        // 模拟点击事件
        link.click()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.order-management {
  // width: 1190px;
  width: 100%;
  .mainbody {
    .info {
      padding: 20px;
      background: #fff;
      font-size: 14px;
      .info-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        b {
          font-size: 20px;
          color: rgba(0, 0, 0, 0.8);
        }
      }
      .ant-row-flex {
        :nth-child(4) {
          color: #ffffff;
          background-color: rgb(24, 144, 255);
        }
        .ant-col {
          line-height: 30px;
          text-align: center;
          height: 30px;
          font-size: 14px;
          border-radius: 3px;
        }
      }
      .contcat {
        padding: 40px 0 0 0;
      }
      .btn {
        border: none;
        border-radius: 3px;
        width: 130px;
        height: 38px;
      }
      .cl69 {
        background-color: rgba(132, 0, 255, 0.698039215686274);
      }
      .c130 {
        background-color: rgb(130, 130, 130);
      }
      .lh32 {
        line-height: 30px;
        text-align: left;
      }
    }
  }
}
// 支付样式
.left-c {
  line-height: 32px;
  text-align: right;
}
.left-b {
  line-height: 10px;
  text-align: left;
}
.logo {
  width: 143px;
  height: 55px;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  img {
    margin-top: 5px;
    width: 100%;
  }
  .pt5 {
    padding: 5px 0 0 0;
  }
}
.zs {
  height: 250px;
}
.pt20 {
  padding: 0 0 0 20px;
}
/deep/ .ant-col {
  margin-bottom: 18px;
}
/deep/ .ant-radio-wrapper {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}
/deep/ .ant-radio {
  &:nth-child(2) {
    width: 100%;
  }
}
/deep/.ant-input-number:hover {
  border-color: red;
}
/deep/.ant-input-number-focused {
  border-color: red;
  box-shadow: none;
}
.notice {
  color: #333;
  font-size: 14px;
}
.red {
  color: red;
}
.col3 {
  font-size: 16px;
  color: #333;
}
/deep/ .ant-tag {
  font-size: 14px;
  line-height: 32px;
}
.row-handle {
  margin-bottom: 10px;
  margin-top: 24px;
}
.modal-t {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.modal-title {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  margin-bottom: 20px;
}
.modal-info {
  padding: 0 20px;
  margin-bottom: 42px;
  .modal-content {
    margin-left: 32px;
    min-height: 41px;
    background: #f2f2f2;
    padding: 10px;
    margin-bottom: 16px;
    a {
      text-decoration: underline;
      color: @primary;
    }
  }
  .modal-icon {
    font-size: 26px;
    margin-right: 6px;
    margin-bottom: 8px;
  }
  .modal-hint {
    margin-left: 12px;
    color: #b8741a;
    font-weight: 700;
  }
}
.option-select {
  width: 108px;
  /deep/ .ant-select-selection__rendered {
    margin-left: 16px;
  }
}
</style>